import Link from 'next/link';
import {CommonServerSideParams} from '@/app/types/CommonServerSideParams';
import {OnlyBrowserPageProps} from '@/layouts/web/types/OnlyBrowserPageProps';
import {SSGPageProps} from '@/layouts/web/types/SSGPageProps';
import {
  // getWebLayoutStaticPaths,
  getWebLayoutStaticProps,
} from '@/layouts/web/webLayoutSSG';
// import useCustomer from '@/modules/core/data/hooks/useCustomer';
// import { Customer } from '@/modules/core/data/types/Customer';
import {createLogger} from '@/modules/core/logging/logger';
import {
  GetStaticPaths,
  GetStaticProps, GetStaticPropsResult,
  NextPage,
} from 'next';
import React, {useState, useEffect} from 'react';
import {TimerContainer} from "@/components/Countdown/TimerContainer";
import {TimerInput} from "@/components/Countdown/TimerInput";
import {Header} from "@/components/Countdown/Header";
import {Footer} from "@/components/Countdown/Footer";

import {StaticPropsInput} from '@/app/types/StaticPropsInput';
import {getReactDivApiConfig} from '@/modules/core/reactDivAPI/utils/getApiConfig';
import deepmerge from 'deepmerge';
import classnames from "classnames";


const fileLabel = 'pages/index';
const logger = createLogger({ // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
  fileLabel,
});

/**
 * Only executed on the server side at build time.
 * Necessary when a page has dynamic routes and uses "getStaticProps".
 */
// export const getStaticPaths: GetStaticPaths<CommonServerSideParams> = getPublicLayoutStaticPaths({fallback:true});
// export const getStaticPaths: GetStaticPaths<CommonServerSideParams> = getPublicLayoutStaticPaths();

/**
 * Only executed on the server side at build time.
 *
 * @return Props (as "SSGPageProps") that will be passed to the Page component, as props.
 *
 * @see https://github.com/vercel/next.js/discussions/10949#discussioncomment-6884
 * @see https://nextjs.org/docs/basic-features/data-fetching#getstaticprops-static-generation
 */
export const getStaticProps: GetStaticProps<SSGPageProps, CommonServerSideParams> = async (props: StaticPropsInput): Promise<GetStaticPropsResult<SSGPageProps>> => {
  const commonStaticProps: GetStaticPropsResult<SSGPageProps> = await getWebLayoutStaticProps()(props);
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.info('getStaticProps commonStaticProps', commonStaticProps); // eslint-disable-line no-console
  }

  if ('props' in commonStaticProps) {
    const config = getReactDivApiConfig();

    const [
      // Below is Web Module
      // currentShelledPage,
      // currentClientProjectSEOSettings,
      // currentShelledPageSeoSettings, // NOTE This is a dependency, should loop through these
      // currentClientProjectSEOSettings,
      // Above is Web Module
    ] = await Promise.all([
      // Below is Web Module
      // getShelledPageBySingularAPIID({config, slug: 'home'}),
      // getSystemShelledFieldsBySystemShellID({config, systemShellID: '630f756db23725aedbc16e7d'}),
      // getClientProjectContentBySystemShellID({
      //   config,
      //   clientID: commonStaticProps.props.reactDivClientID,
      //   projectID: commonStaticProps.props.reactDivClientProjectID,
      //   systemShellID: '630f756db23725aedbc16e7d',
      // }),
      // getBySingularAPIID({config, slug: "home"}),
      // Above is Web Module
    ]);

    return deepmerge(commonStaticProps, {
      props: {
        // Below is Web Module
        // currentShelledPage,
        // currentShelledPageSeoSettings,
        // currentClientProjectSEOSettings,
        // Above is Web Module
        builtAt: new Date().toISOString(),
      },
      // revalidate: regenerationDelay,
    });
    // return commonStaticProps;
  } else {
    return commonStaticProps;
  }

};
/**
 * SSG pages are first rendered by the server (during static bundling).
 * Then, they're rendered by the client, and gain additional props (defined in OnlyBrowserPageProps).
 * Because this last case is the most common (server bundle only happens during development stage), we consider it a default.
 * To represent this behaviour, we use the native Partial TS keyword to make all OnlyBrowserPageProps optional.
 *
 * Beware props in OnlyBrowserPageProps are not available on the server.
 */
type Props = {} & SSGPageProps<Partial<OnlyBrowserPageProps>>;

let membersDemo: any = [
  {
    profilePicture: "bg-[url('/static/images/members/01.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
  {
    profilePicture: "bg-[url('/static/images/members/02.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
  {
    profilePicture: "bg-[url('/static/images/members/03.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
  {
    profilePicture: "bg-[url('/static/images/members/04.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
  {
    profilePicture: "bg-[url('/static/images/members/05.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
  {
    profilePicture: "bg-[url('/static/images/members/06.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
  {
    profilePicture: "bg-[url('/static/images/members/07.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
  {
    profilePicture: "bg-[url('/static/images/members/08.jpg')]",
    name: 'name',
    location: 'ipswich, QLD',
  },
]
let navigationLinks = [
  {
    destination: "dating-advice",
    linkText: "Dating Advice",
  },
  {
    destination: "local-dating",
    linkText: "Local Dating",
  },
  {
    destination: "success-stories",
    linkText: "Success Stories",
  }
]

/**
 * Example of a public page.
 */
const Home: NextPage<Props> = (props): JSX.Element => {
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.info('Index props', props); // eslint-disable-line no-console
  }

  const handleClick = () => {

    setTime(newTime);
    console.log(time);
    setNewTime(0);
  };

  const handleChange = (e: any) => {
    let inputTime = e.target.value;
    setNewTime(inputTime);

  };

  const [time, setTime] = useState<number>(0);
  const [newTime, setNewTime] = useState<number>(0)
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [message, setMessage] = useState<string>("");

  const timeToDays = time * 60 * 60 * 24 * 1000;
  let countDownDate = new Date("November 1, 2023 08:00:00").getTime() + timeToDays;

  useEffect(() => {
    var updateTime = setInterval(() => {
      var now = new Date().getTime();

      var difference = countDownDate - now;

      var newDays = Math.floor(difference / (1000 * 60 * 60 * 24));
      var newHours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var newMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      var newSeconds = Math.floor((difference % (1000 * 60)) / 1000);

      setDays(newDays);
      setHours(newHours);
      setMinutes(newMinutes);
      setSeconds(newSeconds);

      if (difference <= 0) {
        clearInterval(updateTime);
        setMessage("The Launch Has Started");
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      }
    })

    return () => {
      clearInterval(updateTime);
    }

  }, [time]);

  return (
    <>
      <div className="mx-auto mt-2">
        <div
          className={classnames(
            "max-w-7xl mx-auto flex flex-col justify-between items-center px-4 sm:px-6 py-2 md:space-x-10"
          )}
        >
          <Header message={message}/>
          <TimerContainer
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
          <Footer/>
          {/*<TimerInput value={newTime} handleClick={handleClick} handleChange={handleChange} />*/}
        </div>
      </div>
    </>
  );

};

export default Home;
