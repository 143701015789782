import React from 'react'
import Image from "next/image";
import Link from 'next/link'
import classnames from "classnames";

interface msgProps {
  message: string,
};

export const Header = ({message}: msgProps) => {
  return (
    <>
      <Link
        href="/home"
        className="flex"
      >
        <span className="sr-only">Where Adults Meet</span>
        <Image
          alt="Where Adults Meet primary logo"
          src={"/static/images/logo/WAMLOGOFINAL-whiteol.png"}
          height={120}
          width={154}
        />
      </Link>
      <h1 className="text-2xl mt-8 md:text-4xl font-bold font-redhat text-rose-500 font-bold text-center mx-auto ">
        {message ? message : "WE'RE LAUNCHING SOON"}
      </h1>
    </>
  )
}
